import React, { useEffect, useState } from 'react';
import { TempoDisplay } from '../tempo/TempoDisplay';
// import * as Tone from 'tone';
import { TempoChange } from '../tempo/TempoChange';
import { MeterChange } from '../meter/MeterChange';
import { Tone, startLoop, stopLoop, initMetronome } from '../tone';
import { MuteButton } from '../volume/MuteButton';
import { clickGain } from '../tone';
import { VolumeControl } from '../volume/VolumeControl';
import { SubdivisionSelect } from '../meter/SubdivisionSelect';
import { ItalianTempo } from '../tempo/ItalianTempo';
import { TapTempo } from '../tempo/TapTempo';
import { StartButton } from './StartButton';
import { StopButton } from './StopButton';
const Metronome = () => {
  const [tempo, setTempo] = useState(120);
  const [meter, setMeter] = useState(4);
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [subdivision, setSubdivision] = useState('4n');
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    Tone.Transport.bpm.value = tempo;

    if (muted) {
      clickGain.gain.value = 0;
    } else {
      clickGain.gain.value = volume;
    }
  }, [tempo, muted, volume]);
  useEffect(() => {
    const loop = initMetronome(meter, subdivision);
    return () => {
      loop.dispose();
    };
  }, [meter, subdivision]);

  return (
    <div className="metronome-container">
      <div className="tempo">
        <TempoDisplay tempo={tempo} setTempo={setTempo} />
        <ItalianTempo tempo={tempo} />

        <TempoChange tempo={tempo} setTempo={setTempo} />
      </div>
      <div className="row">
        <div className="column">
          <VolumeControl
            clickGain={clickGain}
            volume={volume}
            setVolume={setVolume}
          />
        </div>
        <div className="column">
          <TapTempo
            tempo={tempo}
            setTempo={setTempo}
            setMuted={setMuted}
            setIsPlaying={setIsPlaying}
          />
          <div className="button-container">
            {!isPlaying && (
              <StartButton
                startLoop={startLoop}
                setMuted={setMuted}
                setIsPlaying={setIsPlaying}
              />
            )}
            {isPlaying && (
              <StopButton
                stopLoop={stopLoop}
                setMuted={setMuted}
                setIsPlaying={setIsPlaying}
              />
            )}
          </div>
          <MuteButton muted={muted} setMuted={setMuted} Tone={Tone} />
        </div>
        <div className="column">
          <MeterChange meter={meter} setMeter={setMeter} />
          <SubdivisionSelect setSubdivision={setSubdivision} />
        </div>
      </div>
    </div>
  );
};

export default Metronome;
