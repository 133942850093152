import React from 'react';

export const MeterChange = ({ meter, setMeter }) => {
  return (
    <div className="selector-container">
      <label id="meter-label" htmlFor="meter">
        Beats
      </label>
      <select
        id="meter-select"
        defaultValue={meter}
        onChange={(e) => {
          const newMeter = e.target.value;
          setMeter(newMeter);
        }}
      >
        <option value={2}>n2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
        <option value={6}>6</option>
        <option value={7}>7</option>
        <option value={9}>9</option>
        <option value={11}>11</option>
        <option value={13}>13</option>
      </select>
    </div>
  );
};
