import React from 'react';

export const StartButton = ({ startLoop, setMuted, setIsPlaying }) => {
  return (
    <>
      <button
        className="start-stop-button"
        onClick={() => {
          setIsPlaying(true);
          startLoop(setMuted);
        }}
      >
        Start
      </button>
    </>
  );
};
