import React from 'react';

export const TempoDisplay = ({ tempo, setTempo }) => {
  return (
    <div className="tempo-flash">
      <h3 id="tempo">{tempo > 300 ? 300 : tempo}</h3>
      <h3 id="bpm">BPM</h3>
    </div>
  );
};
