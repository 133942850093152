import React from 'react';

export const StopButton = ({ stopLoop, setIsPlaying }) => {
  return (
    <>
      <button
        className="start-stop-button"
        onClick={() => {
          setIsPlaying(false);
          stopLoop();
        }}
      >
        Stop
      </button>
    </>
  );
};
