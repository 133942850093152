import React from 'react';
import { calculateTempo } from '../tone';
import * as Tone from 'tone';

export const TapTempo = ({ setTempo, setMuted, setIsPlaying }) => {
  let temposArray = [];
  let tapCount = 0;

  const handleTap = () => {
    if (Tone.context.state === 'suspended') {
      Tone.start();
    }
    Tone.Transport.start();
    setMuted(true);
    setIsPlaying(false);

    tapCount++;
    const BPM = calculateTempo();
    if (tapCount < 4) {
      temposArray.push(BPM);
    } else if (tapCount === 4) {
      const avgTempo = Math.floor(
        temposArray.reduce((a, b) => a + b) / temposArray.length
      );
      if (avgTempo !== Infinity) {
        setTempo(avgTempo);
      }
    } else if (tapCount === 8) {
      const avgTempo = Math.floor(
        temposArray.reduce((a, b) => a + b) / temposArray.length
      );
      if (avgTempo !== Infinity) {
        setTempo(avgTempo);
      }

      temposArray = [];
      tapCount = 0;
    }
  };

  return (
    <div className="button-container">
      <button id="tap-tempo" onClick={handleTap}>
        Tap
      </button>
    </div>
  );
};
