import './App.css';
import Metronome from './metronome/metronome/Metronome';
const App = () => {
  return (
    <div className="App ">
      <div className="container">
        <div className="header">
          <h1>Magic Metronome</h1>
        </div>

        <Metronome />
      </div>
    </div>
  );
};

export default App;
