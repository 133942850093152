import * as Tone from 'tone';

const monoSynth = new Tone.MonoSynth({
  oscillator: {
    type: 'square',
    partials: [1, 0.5],
  },
  filter: {
    type: 'bandpass',
    frequency: 1000,
  },
  envelope: {
    attack: 0,
    decay: 1,
    sustain: 1,
    release: 0,
  },
});

const filter = new Tone.Filter('500', 'bandpass');
const clickGain = new Tone.Gain(0.5).toDestination();
monoSynth.connect(filter);
filter.connect(clickGain);

function startLoop(setMuted) {
  setMuted(false);
  if (Tone.context.state === 'suspended') {
    Tone.start();
  }
  Tone.Transport.start();
}
function stopLoop() {
  Tone.Transport.stop();
}

const calculateTicks = (meter, division) => {
  const num = division.match(/\d+/);
  let numTicks;
  if (division.includes('t')) {
    numTicks = meter * 3;
  } else {
    numTicks = (num / 4) * meter;
  }
  return Array.from({ length: numTicks }, (_, i) => i);
};

const initMetronome = (meter = 4, division = '4n') => {
  const ticks = calculateTicks(meter, division);

  const loop = new Tone.Sequence(
    (time, event) => {
      event === 0
        ? monoSynth.triggerAttackRelease('Db6', '0.05', time)
        : monoSynth.triggerAttackRelease('Db5', '0.05', time);
    },
    ticks,
    division
  ).start(0);
  return loop;
};

const calculateTempo = () => {
  const elapsedTime = Tone.Transport.seconds;
  const BPM = Math.round(60 / elapsedTime);
  Tone.Transport.stop().start();

  return BPM;
};

export { Tone, startLoop, stopLoop, initMetronome, clickGain, calculateTempo };
