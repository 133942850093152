import React from 'react';

export const TempoChange = ({ tempo, setTempo }) => {
  return (
    <div className="input-container">
      <input
        id="temp-slider"
        className="slider"
        type="range"
        min="0"
        max="300"
        value={tempo}
        onChange={(e) => {
          setTempo(parseInt(e.target.value));
        }}
      />
      <div className="button-container">
        <button
          className="change-tempo-button"
          onClick={() => {
            const newTempo = tempo - 1 >= 0 ? tempo - 1 : 0;
            setTempo(newTempo);
          }}
        >
          -
        </button>
        <button
          className="change-tempo-button"
          onClick={() => {
            const newTempo = tempo + 1;
            setTempo(newTempo);
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};
