import React from 'react';

export const SubdivisionSelect = ({ setSubdivision }) => {
  return (
    <div id="subdivision-container">
      <select
        id="subdivision-select"
        onChange={(e) => {
          setSubdivision(e.target.value);
        }}
      >
        <option value="4n">Quarter</option>
        <option value="8n">Eighth</option>
        <option value="8t">Triplet</option>
        <option value="16n">16th</option>
      </select>
    </div>
  );
};
