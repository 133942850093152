import React from 'react';

export const VolumeControl = ({ volume, setVolume }) => {
  return (
    <div className="volume-container">
      <label id="volume-label" htmlFor="meter">
        Volume
      </label>
      <input
        id="volume-control"
        className="slider"
        type="range"
        min={0}
        max={1}
        step={0.01}
        defaultValue={volume}
        onChange={(e) => {
          setVolume(parseFloat(e.target.value));
        }}
      />
    </div>
  );
};
