import React from 'react';

const calculateTempoMarking = (tempo) => {
  switch (true) {
    case tempo < 20:
      return 'Larghissimo';
    case tempo <= 40:
      return 'Solenne';
    case tempo <= 60:
      return 'Largo';
    case tempo <= 66:
      return 'Larghetto';
    case tempo <= 76:
      return 'Adagio';
    case tempo <= 108:
      return 'Andante';
    case tempo <= 120:
      return 'Moderato';
    case tempo <= 156:
      return 'Allegro';
    case tempo <= 176:
      return 'Vivace';
    case tempo < 200:
      return 'Presto';
    default:
      return 'Prestissimo';
  }
};

export const ItalianTempo = ({ tempo }) => {
  return (
    <div className="text-container ">
      <h4 id="italian-tempo">{calculateTempoMarking(tempo)}</h4>
    </div>
  );
};
