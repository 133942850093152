import React from 'react';

export const MuteButton = ({ muted, setMuted, Tone }) => {
  return (
    <div id="mute-container">
      <label id="mute-label">
        <input
          id="mute-button"
          type="checkbox"
          name="checkbox-checked"
          onChange={async (e) => {
            console.log('checked', e.target.checked);

            if (e.target.checked === true) {
              await setMuted(true);
            } else {
              await setMuted(false);
            }
          }}
        />
        Mute
      </label>
    </div>
  );
};
